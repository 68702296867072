$(document).on('turbolinks:before-cache', function () {

    try {
        $('select.select2').select2('destroy');
        $('.select-search-select2').select2('destroy');
        // $('select.select2').trigger('change')
    } catch (e) {

    }

});


window.EventoOcultarLocationDevice = function () {
    $(".direccion_devices").unbind().on("change", function () {
        var direccion = $(this).val()
        if (direccion == "EMPRESA") {
            $(this).parent().parent().find(".employee_devices").removeClass("large-4");
            $(this).parent().parent().find(".employee_devices").addClass("large-2");
            $(this).parent().parent().find(".location_devices").removeClass("hide");
        } else {
            $(this).parent().parent().find(".location_devices").addClass("hide");
            $(this).parent().parent().find(".employee_devices").removeClass("large-2");
            $(this).parent().parent().find(".employee_devices").addClass("large-4");
        }
    })
}

window.imageToBlob = function (imageURL) {
    const img = new Image;
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");
    img.crossOrigin = "";
    img.src = imageURL;
    return new Promise(resolve => {
        img.onload = function () {
            c.width = this.naturalWidth;
            c.height = this.naturalHeight;
            ctx.drawImage(this, 0, 0);
            c.toBlob((blob) => {
                // here the image is a blob
                resolve(blob)
            }, "image/png", 0.75);
        };
    })
}
window.VerImagenModal = function () {
    $(".copiar-imagen").unbind().on("click", async function (e) {
        try {
            e.preventDefault();
            var _this = $(this);
            var src = $(this).data("url");
            var img = new Image();
            img.src = src;
            var text = '<img src=" ' + img.src + '" />';
            //const blob = await imageToBlob(src)
            var type = "text/html";
            var blob = new Blob([text], { type });
            var data = [new ClipboardItem({ [type]: blob })];
            //const item = new window.ClipboardItem({ "text/html": src });
            navigator.clipboard.write(data);
            _this.html("<i class='fa fa-check-circle'></i> Copiado")
            setTimeout(function () {
                _this.html("<i class='fa fa-clipboard'></i>")
            }, 3000)
        } catch (e) {

        }

    })
    $(".ver-imagen").unbind().on("click", function (e) {
        var src = $(this).data("url");
        $("#preview-imagen").find('.loading').show();
        $("#preview-imagen").find("img").prop('src', "");
        $("#preview-imagen").foundation('open');
        var img = new Image();
        img.src = src;
        img.onload = function () {
            $("#preview-imagen").find("img").prop('src', img.src);
            $("#preview-imagen").find('.loading').hide();
        }
        e.preventDefault();
    })
    $(".ver-pdf").unbind().on("click", function (e) {
        var src = $(this).data("url");
        var name = $(this).data("nombre");
        $("#preview-pdf").find(".force_download").attr("data-nombre", name)
        $("#preview-pdf").find(".force_download").attr("data-url", src)
        $("#preview-pdf").foundation('open')
        render_all_pdf(src)
        e.preventDefault();
    })
    $("iframe#preview-document-iframe").unbind().on("load", function () {
        $("iframe#preview-document-iframe").show()
        $("#preview-pdf").find(".loading").hide();
    });
}

window.loadAttachments = function (token, employee = false, tipo = null) {
    if (employee) {
        var tipos = ["documento-identidad", "curriculum", "antecedente-policial-penal", "consulta-deuda", "competencia", "acuerdo-confidencialidad", "contrato-trabajo", "documentos-baja", "otros-archivos"]
        for (var i = 0; i <= tipos.length - 1; i++) {
            var tipo = tipos[i]
            console.log(tipo)
            var authenticity_token = $('#authenticity_token_' + token + "_" + tipo).val();
            var modelo = $('#modelo_' + token + "_" + tipo).val();
            var modelo_token = token;
            try {
                $('#drop-zone-attachments_' + token + "_" + tipo).filePicker({
                    url: '/attachments/upload',
                    data: {

                        "authenticity_token": authenticity_token,
                        "modelo": modelo,
                        "modelo_token": modelo_token,
                        "tipo": tipo

                    },
                    dropZone: $('.drop-aqui-' + modelo_token + "_" + tipo),
                    dropWindow: '.drop-windows',
                    autoLoad: true,
                    acceptFileTypes: /(\.|\/)(pdf|gif|jpe?g|png|doc|docx|xls|xlsx|zip|txt|xml|json)$/i,
                    maxFileSize: 5242880, //(= 5MB)
                    plugins: ['ui', 'drop', 'crop'],
                    messages: {
                        uploadFallback: 'El navegador no soporta subir archivos.',
                        minFileSize: 'El archivo no debe ser menor a :min KB.',
                        maxFileSize: 'El archivo no debe ser mayor a 5MB',
                        postMaxSize: 'El archivo excede el límite de tamaño máximo de :max MB.',
                        invalidFileType: 'El tipo de archivo no está soportado. Debe ser formato PDF.',
                        error: 'Oops! Ocurrio un error.',
                        abort: 'La operación fue abortada.',
                        // UI plugin
                        processing: 'Procesando...',
                        deleteFail: 'No se puede eliminar :file.',
                    },
                    ui: {
                        perPage: 20,
                        autoLoad: true,
                        selectors: {
                            filesList: '.files-' + modelo_token + "_" + tipo
                        }
                    },
                    crop: {
                        container: $('.crop-container')
                    }
                }).on('done.filepicker', function (e, data) {
                    if (tipo) {
                        $('#files-' + data.files[0].modelo_token + "_" + data.files[0].tipo).find(".download-template").each(function (i, tr) {
                            console.log($(tr))
                            if ($(tr).attr("name") == data.files[0].name) {
                                $(tr).remove()
                            }
                        })
                    }
                }).on('renderdone.filepicker', function (e, data) {
                    $(document).foundation();
                    VerImagenModal();

                }).on('fail.filepicker', function (e, data) {
                    console.log(data)
                    alert("No se pudieron cargar los documentos, intente mas tarde")
                }).on('delete.filepicker', function (e, data) {
                    data.token = data.token_file
                }).on('deletedone.filepicker', function (e, data) {

                }).on('deleteall.filepicker', function (e) {
                    if (!confirm("Está seguro de eliminar TODOS los archivos?, esta acción no se puede deshacer.")) {
                        return false;
                    }
                });
            } catch (e) {

            }
        }
    } else {
        console.log(tipo)
        var authenticity_token = $('#authenticity_token_' + token + (tipo ? ("_" + tipo) : "")).val();
        var modelo = $('#modelo_' + token + (tipo ? ("_" + tipo) : "")).val();
        var modelo_token = token;
        try {
            $('#drop-zone-attachments_' + token + (tipo ? ("_" + tipo) : "")).filePicker({
                url: '/attachments/upload',
                data: function () {
                    return {
                        "authenticity_token": authenticity_token,
                        "modelo": modelo,
                        "modelo_token": modelo_token,
                        "tipo": tipo
                    }
                },
                dropZone: $('.drop-aqui-' + modelo_token + (tipo ? ("_" + tipo) : "")),
                dropWindow: '.drop-windows',
                autoLoad: true,
                acceptFileTypes: /(\.|\/)(pdf|gif|jpe?g|png|doc|docx|xls|xlsx|zip|txt|xml|json)$/i,
                maxFileSize: 5242880, //(= 5MB)
                plugins: ['ui', 'drop', 'crop'],
                messages: {
                    uploadFallback: 'El navegador no soporta subir archivos.',
                    minFileSize: 'El archivo no debe ser menor a :min KB.',
                    maxFileSize: 'El archivo no debe ser mayor a 5MB',
                    postMaxSize: 'El archivo excede el límite de tamaño máximo de :max MB.',
                    invalidFileType: 'El tipo de archivo no está soportado. Debe ser formato PDF.',
                    error: 'Oops! Ocurrio un error.',
                    abort: 'La operación fue abortada.',
                    // UI plugin
                    processing: 'Procesando...',
                    deleteFail: 'No se puede eliminar :file.',
                },
                ui: {
                    perPage: 20,
                    autoLoad: true,
                    selectors: {
                        filesList: '.files-' + modelo_token + (tipo ? ("_" + tipo) : "")
                    }
                },
                crop: {
                    container: $('.crop-container')
                }
            }).on('done.filepicker', function (e, data) {
                console.log("done")
            }).on('renderdone.filepicker', function (e, data) {
                $(document).foundation();
                VerImagenModal();
                console.log("renderdone")
            }).on('fail.filepicker', function (e, data) {
                console.log(data)
                alert("No se pudieron cargar los documentos, intente mas tarde")
            }).on('delete.filepicker', function (e, data) {
                data.token = data.token_file
            }).on('deletedone.filepicker', function (e, data) {

            }).on('deleteall.filepicker', function (e) {
                if (!confirm("Está seguro de eliminar TODOS los archivos?, esta acción no se puede deshacer.")) {
                    return false;
                }
            });
        } catch (e) {

        }
    }


}
window.BindModalDescripTicket = function () {
    $("#cerrar_modal_descripcion_ticket").unbind().on("click", function () {
        $('#modal_ver_descripcion').foundation('close'); $('#modal_ver_descripcion').html('')
    });

}
$(document).on('turbolinks:load', function () {

    $('[data-reveal]').on('open.zf.reveal', function () {
        setTimeout(function () {
            $(document).foundation();
        }, 1000)
    });



    $(".clean_ticket_filter").on("click", function(){
        var _this = $(this);
        var id = _this.attr("filter");
        $("#" + id).val([]).trigger('change');
        setTimeout(() => {
            SelectTwoAjax();
        },300);
        _this.hide();
        return false;
    })

    $(".copy-content-ws").on("click", async function(){
       var _this = $(this);
       var content = _this.attr("content");

        try {
            await window.navigator.clipboard.writeText(content);
            console.log('Contenido copiado al portapapeles');
        } catch (err) {
            console.error('Error al copiar: ', err);
        }
       _this.html("<span class='label warning bold'><i class='fa fa-check-circle'></i> Copiado <i class='fa fa-whatsapp'></i></span>");
       setTimeout(function(){
           _this.html("<span class='label bold' style='background: #6BD361'>Copiar <i class='fa fa-whatsapp'></i></span>");
       },4000);

    });

    $("#ticket_reply_archives").on("change", function () {
        var name = $(this)[0].files.length == 1 ? "Adjunto: " + $(this)[0].files[0].name : "Adjuntos: " + $(this)[0].files.length + " archivos"
        $(".ticket_reply_archives").html("<i class='fa fa-paperclip'></i> Adjuntos [" + $(this)[0].files.length + "]")
        $("#cantidad_archivos_responder").text(name)
    });
    $(".marcar_leido_business_message").on("click", function () {
        var id = $(this).data("id");
        $("#tr_business_message_" + id).find(".spanes").html("");
        $("#tr_business_message_" + id).removeClass("new_business_message");
        $("#tr_business_message_" + id).find(".spanes").html("<span class='label success'>LEIDO<i class='fa fa-check'/></span>")
        //$("#tr_business_message_" + id).find(".marcar_leido_business_message").html("<span class='bold'>RE-LEER<i class='fa fa-envelope-open-o' /></span>")
    });
    $("#absense_motivo").on("change", function () {
        var absense_employee_id = $("#absense_relevo_temporal_employee_id").val();
        var motivo = $("#absense_motivo").val();
        $.ajax({
            url: "/restricciones_employee",
            type: "POST",
            data: { id: absense_employee_id, motivo: motivo },
            success: function (e) {
                recargar_picker(e.restricciones)
            },
            error: function (e) {
                alert(e)
            }
        })
    });

    $("#absense_inicio").on("blur input changed", function () {
        setTimeout(function () {
            var fecha = $("#absense_inicio").attr("f");
            console.log(fecha)
            picker_desde(fecha)
        }, 600)
    });
    $("#absense_relevo_temporal_employee_id").on("change", function () {
        var absense_employee_id = $(this).find(":selected").val();
        var motivo = $("#absense_motivo").val();
        $.ajax({
            url: "/restricciones_employee",
            type: "POST",
            data: { id: absense_employee_id, motivo: motivo },
            success: function (e) {
                recargar_picker(e.restricciones)
            },
            error: function (e) {
                alert(e)
            }
        })

    });
    $(".email_delete_account,.motivo_delete_account").unbind().on("keyup input load", function () {
        var email = $(this).data("email");
        var valor = $(".email_delete_account").val();
        var motivo = $(".motivo_delete_account").val().length > 0;
        if (email == valor && motivo)
            $(".submit_delete_account").attr("disabled", false);
        else
            $(".submit_delete_account").attr("disabled", true);

    });
    $("#btnExport").unbind().on("click", function (e) {
        e.preventDefault();
        $(".tableexport-caption").html("")
        $("#table_to_excel").tableExport({
            headers: true,                      // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
            footers: true,                      // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
            formats: ["xlsx", 'csv'],    // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
            filename: "ReporteAsistencias",                     // (id, String), filename for the downloaded file, (default: 'id')
            bootstrap: false,                   // (Boolean), style buttons using bootstrap, (default: true)
            exportButtons: true,                // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
            position: "top",                 // (top, bottom), position of the caption element relative to table, (default: 'bottom')
            ignoreRows: null,                   // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
            ignoreCols: null,                   // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
            trimWhitespace: true,               // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
            RTL: false,                         // (Boolean), set direction of the worksheet to right-to-left (default: false)
            sheetname: "Reporte Asistencias"                     // (id, String), sheet name for the exported spreadsheet, (default: 'id')
        });
        setTimeout(function () {
            $(".tableexport-caption").prepend(`<h3 style="margin-bottom: 15px">Opciones de exportación</h3>`);
            $(".tableexport-caption").append(`<span class="cerrar-table-export" style="position: absolute;right: 10px;top:10px;cursor: pointer"><i class="fa fa-close"></i></span>`)
            $(".cerrar-table-export").unbind().on("click", function () {
                $(".tableexport-caption").remove();
            });
        }, 50)

        return false;
    })
    EventoBuscarActivo();
    EventoEnviarAlmacenActivo();
    EventoOcultarLocationDevice();

    $(".pausar_ticket").on("change",function() {
        var checked = $(this).is(':checked')
        var id = $(this).data("ticket-id")
        $("#porcentaje_" + id).attr("readonly",checked)
        $("#descripcion_" + id).attr("readonly",checked)
    });
    $(".aprobado_lider_circulo").on("change", function () {
        var checked = $(this).is(':checked')
        if (checked) {
            document.getElementById("absense_lider_circulo_employee_id").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_circulo_3i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_circulo_2i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_circulo_1i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_circulo_4i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_circulo_5i").required = "required";

        } else {
            document.getElementById("absense_lider_circulo_employee_id").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_circulo_3i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_circulo_2i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_circulo_1i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_circulo_4i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_circulo_5i").removeAttribute("required");
        }
    })
    $(".aprobado_lider_alfa").on("change", function () {
        var checked = $(this).is(':checked')
        if (checked) {
            document.getElementById("absense_lider_alfa_employee_id").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_alfa_3i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_alfa_2i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_alfa_1i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_alfa_4i").required = "required";
            document.getElementById("absense_fecha_aprobado_lider_alfa_5i").required = "required";

        } else {
            document.getElementById("absense_lider_alfa_employee_id").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_alfa_3i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_alfa_2i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_alfa_1i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_alfa_4i").removeAttribute("required");
            document.getElementById("absense_fecha_aprobado_lider_alfa_5i").removeAttribute("required");
        }
    })

    $("#email_lista_de_correos").on("input blur", function () {
        var lista_text = $(this).val();
        var lista_array = lista_text.split("\n");
        for (var i = 0; i <= lista_array.length - 1; i++) {
            var email = lista_array[i];
            if (email.toString().trim().length > 0) {
                var valid = ValidateEmail(email.trim());
                if (!valid) {
                    $("#email_lista_de_correos").addClass("is-invalid-input");
                    $("#email_lista_de_correos").attr("data-invalid", "");
                    $("#email_lista_de_correos").attr("aria-invalid", true);
                    $("#email_lista_de_correos_label").addClass("is-invalid-label");
                    $("#email_lista_de_correos_error").text("[" + email.trim() + "] no es un email valido");
                    $("#email_lista_de_correos_error").addClass("is-visible")
                    return;
                } else {
                    $("#email_lista_de_correos").removeClass("is-invalid-input");
                    $("#email_lista_de_correos").removeAttr("data-invalid");
                    $("#email_lista_de_correos").attr("aria-invalid", false);
                    $("#email_lista_de_correos_label").removeClass("is-invalid-label");
                    $("#email_lista_de_correos_error").removeClass("is-visible")
                }
            } else {
                $("#email_lista_de_correos").removeClass("is-invalid-input");
                $("#email_lista_de_correos").removeAttr("data-invalid");
                $("#email_lista_de_correos").attr("aria-invalid", false);
                $("#email_lista_de_correos_label").removeClass("is-invalid-label");
                $("#email_lista_de_correos_error").removeClass("is-visible")
            }

        }
    })


    $(".load_picker").on("click", function () {
        var _this = $(this);
        var tipo = _this.data("tipo");
        if (tipo)
            loadAttachments(_this.data("token"), false, tipo)
        else
            loadAttachments(_this.data("token"), false)

    });
    $(".load_picker_accordion").on("click", function () {
        var _this = $(this);
        loadAttachments(_this.data("token"), true)
    });


    //OnlyNumbers
    OnlyNumbers();

    function OnlyNumbers() {
        $('input.only_numbers').keyup(function () {
            string = $(this).val();
            $(this).val(string.replace(/[^\d\.]/g, '').replace(/^\.*/, '').replace(/(\.\d*)(.*)/, '$1'));
        });
    };

    ConsultarRucEvents();
    ExtraerRuc();
    setTimeout(function () {
        markjs();
    }, 600)
    $(".mark_search").on("input", markjs);
    $("#event_tipo").on("change", function () {
        var tipo = $(this).find(":selected").val();

        if (tipo == "MANTENIMIENTO PROGRAMADO") {
            $("#event-preview").attr("class", `news  warning`);
        }
        else if (tipo == "COMUNICADO URGENTE") {
            $("#event-preview").attr("class", `news  alert`);
        }
        else if (tipo == "PROMOCIONES / OFERTAS") {
            $("#event-preview").attr("class", `news  success`);
        }





    })
    $("#event_descripcion").on("input load", function () {
        var tipo = $("#event_tipo").val();
        var texto = $("#event_descripcion").val()
        if (tipo == "MANTENIMIENTO PROGRAMADO") {
            $("#event-preview").attr("class", `news ${texto.length == 0 ? "hide" : ""} warning`);
        }
        else if (tipo == "COMUNICADO URGENTE") {
            $("#event-preview").attr("class", `news ${texto.length == 0 ? "hide" : ""} alert`);
        }
        else if (tipo == "PROMOCIONES / OFERTAS") {
            $("#event-preview").attr("class", `news ${texto.length == 0 ? "hide" : ""} success`);
        }
        $("#event-text-preview").empty()
        $("#event-text-preview").append(texto);
    })
    $(".boton-flotante").click(function () {
        if (!$(".opciones-flotante").hasClass("show")) {
            $(".opciones-flotante").removeClass("animate__fadeOutRight")
            $(".opciones-flotante").removeClass("hide")
            $(".opciones-flotante").addClass("show")
            $(".opciones-flotante").addClass("animate__fadeInRight")
            $(".boton-flotante .add").addClass("animate__rotateOut").addClass("hide")
            $(".boton-flotante .close").removeClass("hide").removeClass("animate__rotateOut").addClass("animate__rotateIn")
            $(".boton-flotante").addClass("open")

        } else {
            $(".opciones-flotante").removeClass("animate__fadeInRight")
            $(".opciones-flotante").removeClass("show")
            $(".opciones-flotante").addClass("animate__fadeOutRight")
            $(".boton-flotante .close").addClass("animate__rotateOut").addClass("hide")
            $(".boton-flotante .add").removeClass("animate__rotateOut").removeClass("hide").addClass("animate__rotateIn")
            $(".boton-flotante").removeClass("open")
            setTimeout(function () {
                $(".opciones-flotante").addClass("hide")
            }, 500)
        }

    });

    SelectTwoAjax();
    $(".guardar_indicadores").on("click", function () {
        var dia = $("#fecha_fecha_3i").val();
        var mes = $("#fecha_fecha_2i").val();
        var anio = $("#fecha_fecha_1i").val();
        var fecha = new Date(anio, mes - 1, dia);
        var employee = $(this).data("employee");
        var marcar = $(this).data("marcar");
        var data = []
        console.log(fecha)
        $(".circles").each(function (i, circle) {
            var circle_id = 0;
            var indicator = 0;
            var cantidad = 0;
            var observaciones = "";
            $(circle).find(".indicators").each(function (x, indicator) {
                $(indicator).find("input").each(function (z, input) {
                    if ($(input).hasClass("circle"))
                        circle_id = $(input).val()
                    if ($(input).hasClass("indicator_type"))
                        indicator = $(input).data("id")
                    if ($(input).hasClass("cantidad"))
                        cantidad = $(input).val()
                })
                data.push({
                    fecha: fecha,
                    circle: parseInt(circle_id),
                    indicator_type: parseInt(indicator),
                    cantidad: parseInt(cantidad)
                })
            });
        })

        $.ajax({
            url: "/mis_indicadores_nuevo_ajax",
            type: "POST",
            data: { data: data, employee: employee, marcar: marcar },
            success: function (e) {
                if (e.success) {
                    window.location.href = e.redirect
                } else {
                    alert(e.message)
                }
            }
        })

        console.log(data)
    })

    $("#alta_empleado").on("change", function () {
        var _this = $(this);
        var id = _this.val();
        $.ajax({
            url: "/get_employee_alta",
            type: "POST",
            data: {
                id: id
            },
            success: function (e) {
                if (e.success) {
                    $("#alta_numero_documento").html("<b>Número de documento:</b> " + e.data.numero_documento)
                    $("#alta_nombres").html("<b>Nombre Completo:</b> " + e.data.nombres)
                    $("#alta_cargo").html("<b>Cargo:</b> " + e.data.cargo)
                    $("#alta_codigo").html("<b>Código de Trabajador:</b> " + e.data.codigo)
                    $("#iso_user_registration_correo_corporativo").val(e.data.email)
                }
            },
            error: function (e) {

            }
        })
    });

    $("#circulos_indicadores").on("change", function () {
        var _this = $(this);
        var token = _this.val();
        $.ajax({
            url: "/get_circle_indicators",
            type: "POST",
            data: {
                token: token
            },
            success: function (e) {
                if (e.success) {
                    $("#circulos_tipo_indicadores").find("option").remove();
                    $("#circulos_tipo_indicadores").append('<option value>Todos los tipos</option>');
                    $.each(e.data, function (i, row) {
                        $("#circulos_tipo_indicadores").append('<option value=' + row.token + '>' + row.descripcion + '</option>');
                    });
                }
            },
            error: function (e) {

            }
        })
        $.ajax({
            url: "/get_circle_employees",
            type: "POST",
            data: {
                token: token
            },
            success: function (e) {
                if (e.success) {
                    $("#employee").find("option").remove();
                    $("#employee").append('<option value>Todos los Empleados del Circulo</option>');
                    $.each(e.data, function (i, row) {
                        $("#employee").append('<option value=' + row.id + '>' + row.full + '</option>');
                    });
                }
            },
            error: function (e) {

            }
        })
    });
    $(".abrir_notificaciones").unbind().on("click", function () {
        $.ajax({
            url: "/marcar_notificaciones_leidas",
            type: "POST",
            success: function (e) {
                var conteo = parseInt(e.conteo);
                if (conteo > 9)
                    $(".counterTasks").text("9+");
                else if (conteo > 0)
                    $(".counterTasks").text(conteo);
                else
                    $(".counterTasks").remove()
            }
        })

    })

    $(".notificacion_marcar_leido").unbind().on("click", function () {
        var _this = $(this);
        var token = _this.attr("token");
        var ruta = _this.attr("ruta");
        $.ajax({
            url: "/marcar_notificacion_abierta",
            type: "POST",
            data: {
                token: token
            },
            success: function () {
                window.open(ruta, '_blank');
            }
        })
    });

    $("#doc_version_solo_archivo").on("change", function () {
        if (document.getElementById("doc_version_solo_archivo").checked) {
            $(".editor_doc_versions").addClass("hide");
        } else
            $(".editor_doc_versions").removeClass("hide");
    })

    $("#parte_solo_archivo").on("change", function () {
        if (document.getElementById("doc_version_solo_archivo").checked) {
            $(".editor_doc_versions").addClass("hide");
        } else
            $(".editor_doc_versions").removeClass("hide");
    })
    EventoNumeroReunionCircle();
    $(".add_circle_meeting").on("click", function () {

        setTimeout(function () {
            EventoNumeroReunionCircle();
        }, 500)

    });
    $("#meeting_codigo").on("input", function () {
        var _this = $(this);

        var selects = document.querySelectorAll("select.get_google_meet_circle")
        var code = []
        selects.forEach(function (e) {
            code.push($(e).val())
        })


        var codigo = $("#meeting_codigo").val();
        $.ajax({
            url: "/meetings/get_google_meet_circle",
            type: "POST",
            data: {
                circles_code: code.join(","),
                codigo: codigo
            },
            success: function (e) {
                if (e.success) {
                    if (e.codigo)
                        $("#proximo-numero-meeting").text("Número de reunión: " + e.numero_reunion);
                    else
                        $("#proximo-numero-meeting").text("");
                }
            },
            error: function (e) {

            }
        })
    });

    $(function () {
        if ($("#marcar_row").length > 0 || $(".guardar_indicadores").length > 0){
            getLocation();
        }

        setTimeout(function () {
            $(".reveal-overlay").each(function (i, div) {
                if (!$(div).attr("style")) {
                    $("html").removeClass("zf-has-scroll")
                    $("html").removeClass("is-reveal-open")
                    $("html").attr("style", "")
                }
            })
        }, 100);
        var main_color = $("body").attr("main-color");
        $(".menu-color-main a").css({ "color": "#" + main_color });
        $(".callout-color-main").css({ "background": "#" + main_color, color: "#FFFFFF" });
        $(".select2-container--default .select2-results__option--highlighted[aria-selected]").css({
            "background": "#" + main_color,
            color: "#FFFFFF"
        });
    });

    $(".load_attend_detail").on("click", function () {
        var _this = $(this);
        var id = _this.data("id");
        $("#loader_attend").removeClass("hide")
        $("#content_attend").addClass("hide")
        $.ajax({
            url: "/attends/load_details_range",
            type: "POST",
            data: { id: id },
            success: function (e) {
                $("#content_attend").removeClass("hide")
                $("#loader_attend").addClass("hide")
                if (e.success) {
                    $("#content_attend_title").html(e.title)
                    $("#content_attend_tipo").html(e.attend.tipo)
                    $("#content_attend_fecha").html(e.fecha)
                    $("#content_attend_hora").html(e.hora)
                    $("#content_attend_coordenadas").html(`${e.attend.lat},${e.attend.lon}`)
                    $("#content_attend_direccion").html(e.direccion_estimada)
                    $("#content_attend_detail").html(e.detalles)
                } else {
                    $("#content_attend_detail").html(`<h4 class="text-center">${e.message}</h4>`)
                }
            },
            error: function (e) {
                $("#loader_attend").addClass("hide")
                $("#content_attend_detail").html(`<h4 class="text-center">Error de conexión intenta de nuevo</h4>`)
            }
        })
    });


    SelectNested();
    jscolor.installByClassName("jscolor");
    VerifyRuc();

    function VerifyRuc() {
        if ($('.verify_this_ruc').length == 0)
            return;
        $('.verify_this_ruc').on('keyup input load', function () {
            var verify_this_ruc = $('.verify_this_ruc').val() || 0;
            if (verify_this_ruc.length == 11) {
                if (ruc_verify(verify_this_ruc)) {
                    $('.ruc_error').hide();
                    $('.ruc_correct').show();
                    $('.verify_this_ruc').removeClass('field_with_errors');
                    $('.verify_this_ruc_button').removeAttr('disabled');
                } else {
                    $('.ruc_error').show();
                    $('.ruc_correct').hide();
                    $('.verify_this_ruc').addClass('field_with_errors');
                    $('.verify_this_ruc_button').prop('disabled', 'disabled');
                }
            } else {
                $('.ruc_error').hide();
                $('.ruc_correct').hide();
                $('.verify_this_ruc_button').prop('disabled', 'disabled');
            }
        });
        $('.verify_this_ruc').trigger("keyup");
    }

    $(".nested-form a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');

    $(".clickable_row").each(function () {
        var td = $(this).children("td:not(.no_clickable)");
        td.addClass("clickable"), td.on("click", function () {
            var controller = $(this).parents("tr").attr("controller"),
                show = $(this).parents("tr").attr("show"),
                url = "/" + controller + "/" + show;
            openShowModal(url, () => {
            })
        });
    });
    $(".clickable").each(function () {
        var a = $(this).find(".ver_detalle")
        a.on("click", function () {
            var controller = $(this).parents("tr").attr("controller"),
                show = $(this).parents("tr").attr("show"),
                url = "/" + controller + "/" + show;
            openShowModal(url, () => {
            })
        });
    });

    setTimeout(function () {
        let searchParams = new URLSearchParams(window.location.search)
        let ticket = searchParams.get("ticket")
        let reply = searchParams.get("reply")
        if ($("#tr-" + ticket).length == 0) return;
        var controller = $("#tr-" + ticket).attr("controller"),
            show = $("#tr-" + ticket).attr("show"),
            url = "/" + controller + "/" + show;
        openShowModal(url, () => {
            if (reply) {
                $("#reply-" + reply).addClass("border-red");
                setTimeout(function () {
                    $("#reply-" + reply).removeClass("border-red");
                }, 6000)
            }
        })

    }, 300)

    $(".openShowlAjax").click(function () {
        let controller = $(this).attr("controller");
        let show = $(this).attr("show");
        openShowModal("/" + controller + "/" + show, () => {
        })
    });

    $(function () {

        $("form").on('cocoon:after-insert', function () {
            SelectNested();
            EventoBuscarActivo();
            EventoEnviarAlmacenActivo();
            $('.nested-fields:last .select2').select2({
                placeholder: function () {
                    $(this).data('placeholder');
                },
                formatNoMatches: function () {
                    return 'No existe';
                },
                placeholder: "Buscar"
            });
        });
    });

    function EventoEnviarAlmacenActivo() {
        $(".enviar_a_almacen").on("change", function () {
            var _this = $(this)
            var check = _this.is(':checked');
            if (check) {
                _this.parent().parent().parent().find(".div-almacen").removeClass("hide")
                _this.parent().parent().parent().find(".div-delete").addClass("hide")
            } else {
                _this.parent().parent().parent().find(".div-almacen").addClass("hide")
                _this.parent().parent().parent().find(".div-delete").removeClass("hide")
            }

        })
    }

    function EventoBuscarActivo() {
        $(".buscar-activo").on("input keyup", function () {
            var _this = $(this)
            $.ajax({
                url: "/iso_actives/search_active",
                data: { serie: _this.val() },
                type: "post",
                success: function (e) {
                    if (e.success) {
                        _this.parent().parent().find(".active-message").removeClass("hide")
                        _this.parent().parent().find(".descripcion-activo").val(e.data.descripcion)
                        _this.parent().parent().find(".codigo-activo").val(e.data.codigo_activo)
                        _this.parent().parent().find(".active-message").text("Se encontró el dispositivo en la base de datos")
                        setTimeout(function () {
                            _this.parent().parent().find(".active-message").addClass("hide")
                            _this.parent().parent().find(".active-message").text("")
                        }, 5000)
                    }
                }

            })
        })
    }

    $(function () {
        $('#trabajador').on('input keyup', function () {
            var sum = 0;
            $('input.salario_total').each(function () {
                if (!isNaN(this.value) && this.value.length != 0) {
                    sum += parseFloat(this.value);
                }
            });
            $('#employee_total').val(sum.toFixed(2));
        }).trigger('input');
    });

    //Salary

    $("#salary_descuento_adelanto, #salary_descuento_tardanza, #salary_descuento_inasistencia, #salary_retencion_renta, #salary_aporte_snp, #salary_aporte_afp, #salary_comision_afp, #salary_seguro_afp, #salary_total_neto, #salary_aporte_essalud, .salary_total").on('input keyup', function () {
        var descuento_adelanto = $("#salary_descuento_adelanto").val() || 0;

        var salary_descuento_tardanza = $("#salary_descuento_tardanza").val();
        var salary_descuento_inasistencia = $("#salary_descuento_inasistencia").val();

        var salary_aporte_snp = $("#salary_aporte_snp").val();
        var salary_aporte_afp = $("#salary_aporte_afp").val();
        var salary_comision_afp = $("#salary_comision_afp").val();
        var salary_seguro_afp = $("#salary_seguro_afp").val();
        var salary_aporte_essalud = $("#salary_aporte_essalud").val();
        var salary_retencion_renta = $("#salary_retencion_renta").val();
        var salary_total = 0;
        $('.salary_total').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                salary_total += parseFloat(this.value);
            }
        });
        var salary_total_neto = salary_total - salary_descuento_tardanza - salary_descuento_inasistencia - salary_aporte_snp - salary_aporte_afp - salary_comision_afp - salary_seguro_afp - salary_retencion_renta;
        $('#salary_total_neto').val(salary_total_neto.toFixed(2));

        $('#total_por_pagar').val(parseFloat(salary_total_neto).toFixed(2) - parseFloat(descuento_adelanto).toFixed(2));

    });

    $(function () {
        salary_enable_retencion_renta();
        $("#salary_renta_retenida").click(salary_enable_retencion_renta);
    });

    function salary_enable_retencion_renta() {
        if ($('#salary_renta_retenida').is(':checked')) {
            $("#salary_retencion_renta").removeAttr("disabled");
            $('#salary_total_neto').trigger('input');
        } else {
            $("#salary_retencion_renta").attr("disabled", true);
            $("#salary_retencion_renta").val('');
            $('#salary_total_neto').trigger('input');
        }
    }

    $(".para_total, .para_descuento, .descuento_adelanto, .aporte_essalud").on('input keyup', function () {

        var container = $(this).parents(".multiple-line")
        console.log(container.find(".descuento_adelanto").val())

        var descuento_adelanto = container.find(".descuento_adelanto").val() || 0;

        var para_total = 0;
        container.find('.para_total').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                para_total += parseFloat(this.value);
            }
        });
        var para_descuento = 0;
        container.find('.para_descuento').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                para_descuento += parseFloat(this.value);
            }
        });
        var total_neto = para_total - para_descuento;
        container.find('.total_neto').val(total_neto.toFixed(2));

        container.find('.total_por_pagar').val((parseFloat(total_neto).toFixed(2) - parseFloat(descuento_adelanto).toFixed(2)).toFixed(2));
        SumarSalaryTotals()
    });

    function SumarSalaryTotals() {
        var descuento_adelanto = 0;
        $('.descuento_adelanto').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                descuento_adelanto += parseFloat(this.value);
            }
        });
        var total_neto = 0;
        $('.total_neto').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                total_neto += parseFloat(this.value);
            }
        });
        var total_por_pagar = 0;
        $('.total_por_pagar').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                total_por_pagar += parseFloat(this.value);
            }
        });
        var aporte_essalud = 0;
        $('.aporte_essalud').each(function () {
            if (!isNaN(this.value) && this.value.length != 0) {
                aporte_essalud += parseFloat(this.value);
            }
        });

        $('.sum_adelanto').val(parseFloat(descuento_adelanto).toFixed(2));
        $('.sum_total_neto').val(parseFloat(total_neto).toFixed(2));
        $('.sum_total_por_pagar').val(parseFloat(total_por_pagar).toFixed(2));
        $('.sum_essalud').val(parseFloat(aporte_essalud).toFixed(2));
    }

    $(".para_total").trigger("input")

    SelectTwo();


    $(function () {
        $(document).ready(function () {

            $("#download_link").on("keyup", function () {
                $("#download_archivo").prop("disabled", $(this).val().length > 0);
            });

            $("#manual_circle_id").on("change", function () {
                var id = $("#manual_circle_id").val();
                if (!id) {
                    $("#manual_employee_id").find('option').remove();
                    $("#manual_employee_id").val(-1);
                    $("#manual_employee_id").select2()

                }
                CargarEmpleadosPorCirculos(id, function () {
                    $("#manual_employee_id").val(-1);
                    $("#manual_employee_id").select2()
                }, "manual_employee_id")

            });

            $("#circle").on("change", function () {
                var id = $("#circle").val();
                if (!id) {
                    $("#employee").find('option').remove();
                    $("#employee").val(-1);
                    $("#employee").select2()

                }
                CargarEmpleadosPorCirculos(id, function () {
                    $("#employee").val(-1);
                    $("#employee").select2()
                }, "employee")

            });

            $("#circulos_indicadores").on("change", function () {
                var id = $("#circulos_indicadores").val();
                if (!id) {
                    $("#employee").find('option').remove();
                    $("#employee").val(-1);
                    $("#employee").select2()

                }
                CargarEmpleadosPorCirculos(id, function () {
                    $("#employee").val(-1);
                    $("#employee").select2()
                }, "employee")

            });


            setTimeout(function () {
                let id = $("#manual_circle_id").val();
                if (id > 0) {
                    let _id = $("#hidden_employee_id").text();
                    CargarEmpleadosPorCirculos(id, function () {
                        $("#manual_employee_id").val(_id);
                        $("#manual_employee_id").select2().trigger('change');
                    })

                }


            }, 200)
            $("#ticket_entity").on("change", function () {
                var id = $("#ticket_entity").val();
                if (!id) {
                    $("#user_from_entity").find('option').remove();
                    $("#user_from_entity").append('<option value>Elegir usuario</option>');
                    $("#user_from_entity").select2()

                }
                CargarUsersFromEntity(id, function () {
                    $("#ticket_entity").val(-1);
                    $("#ticket_entity").select2()
                })

            });

            $("#user_from_entity").on("change", function () {
                var id = $("#user_from_entity").val();
                CargarUserFromEntity(id, (data) => {
                    console.log(data)
                    $("#ticket_email").val(data.email);
                    $("#ticket_telefono").val(data.telefono_movil);
                    $("#ticket_persona_de_contacto").val(data.nombre_completo);
                })

            });

            function CargarUserFromEntity(id, completo) {
                if (!id)
                    return;
                $.ajax({
                    url: "/get_user_from_entity",
                    type: "POST",
                    data: { user_id: id },
                    success: function (data) {
                        completo(data.data);
                    },
                    error: function (e) {
                        console.log(JSON.stringify(e));
                    }

                })
            }

            function CargarUsersFromEntity(id, completo) {
                if (!id)
                    return;
                $.ajax({
                    url: "/get_users_from_entity",
                    type: "POST",
                    data: { entity_id: id },
                    success: function (data) {

                        $("#user_from_entity").find('option').remove();
                        $("#user_from_entity").append('<option value>Elegir usuario</option>');
                        $.each(data.data, function (key, value) {
                            $("#user_from_entity").append('<option value=' + value.id + '>' + `[${value.plataforma}] ${value.nombre_completo} - ${value.email}` + '</option>');
                        });

                        $("#user_from_entity").select2()
                    },
                    error: function (e) {
                        console.log(JSON.stringify(e));
                    }

                })
            }

            function CargarEmpleadosPorCirculos(id, completo, select_id) {
                if (!id)
                    return;
                $.ajax({
                    url: "/get_circle_employees",
                    type: "POST",
                    data: { circle_id: id },
                    success: function (data) {

                        $("#"+select_id).find('option').remove();
                        $.each(data, function (key, value) {
                            $("#"+select_id).append('<option value=' + value.id + '>' + `${value.full}` + '</option>');
                        });
                        completo();
                    },
                    error: function (e) {
                        console.log(JSON.stringify(e));
                    }

                })
            }

            $(".filter").on("change", function () {
                var filter = $(this).val();
                var type = $(this).attr('type');
                var path_result = $(this).attr('path_result') || "";
                if (filter.length > 0) {
                    if (path_result.length > 0) {
                        window.location.href = path_result + '?' + type + '=' + filter;
                    } else {
                        window.location = '?' + type + '=' + filter;
                    }
                } else {
                    if (path_result.length > 0) {
                        window.location.href = path_result + '?'
                    } else {
                        window.location = '?'
                    }
                }
            });

            $(".filter2").on("change", function () {
                var filter = $(this).val();
                var type = $(this).attr('type');
                var path_result = $(this).attr('path_result') || "";
                if (filter.length > 0) {
                    if (path_result.length > 0) {
                        window.location.href = path_result + '/' + type + '/' + filter;
                    } else {
                        window.location = '/' + type + '/' + filter;
                    }
                } else {
                    if (path_result.length > 0) {
                        window.location.href = path_result + '/'
                    } else {
                        window.location = '/'
                    }
                }
            });
        });
    });

    $(document).foundation();
    $(document).on(
        'open.zf.reveal', '[data-reveal]', function () {
            if ($("#option-aprobar-absense")) {
                $("html").removeClass("zf-has-scroll")
                $("html").removeClass("is-reveal-open")
                $("html").attr("style", "")
            }

        }
    );

    PrevenirClickArriba();


    function PrevenirClickArriba() {
        //Evitar que al hacer click en un href="#" regrese arriba
        $('a[href="#"]').click(function (e) {
            e.preventDefault();
        });
    };

    /* javascript de page/index.html */
    $(function () {
        // valido si estoy en page/index
        if ($("body").length > 0) {

            let suma = 0;
            // mes y dia
            $('.tardanza').each(function () {
                let tardanza = [];
                tardanza.push({ param: $(this).text() });
                suma = suma + parseInt($(this).text());

                let hoy = fechaActual();
                let fecha_rails = $(this).parents('td').find('.fecha_del_mes').text();
                if (hoy === fecha_rails) {
                    if ($(this).text() > 0) {
                        $('#hoy').text(minutos_a_horas($(this).text()))
                    }
                }
            });

            suma = minutos_a_horas(suma);
            $('#mes').text(suma);
            try {
                semana();
            } catch (e) {

            }

        }

        // calculo de semana
        function semana() {
            let semana = [];
            $('.dia_mont').each(function () {
                semana.push({
                    param: $(this).find('b').text(),
                    tardanza: $(this).parents('tr').find('.tardanza').text(),
                    fecha_dia: $(this).parents('tr').find('.fecha_del_mes').text()
                });
            });
            let indices = [];
            semana.forEach(function (persona, index) {
                if (persona.param.includes('lunes')) {
                    indices.push({ i: index });
                    // console.log("posicion CONDICION :" + index + " | fecha: " + persona.param+ " | tardanza: " + persona.tardanza);
                }
            });

            if (indices.length <= 4) {
                let array_cero = semana.slice(indices[0].i, (indices[1].i - 1));
                let array_uno = semana.slice(indices[1].i, (indices[2].i - 1));
                let array_dos = semana.slice(indices[2].i, (indices[3].i - 1));
                let array_tres = semana.slice(indices[3].i,);
                let semana_pasada = semana.slice(semana[0].i, (indices[0].i - 1));

                let id_row_semana_1 = "#semana1";
                render_semana_row(array_cero, id_row_semana_1);

                let id_row_semana_option = "#semana_no_lunes";
                render_semana_row_option(semana_pasada, id_row_semana_option);

                let id_row_semana_2 = "#semana2";
                render_semana_row(array_uno, id_row_semana_2);

                let id_row_semana_3 = "#semana3";
                render_semana_row(array_dos, id_row_semana_3);

                let id_row_semana_4 = "#semana4";
                render_semana_row(array_tres, id_row_semana_4);

            } else {
                let array_cero = semana.slice(indices[0].i, (indices[1].i - 1));
                let array_uno = semana.slice(indices[1].i, (indices[2].i - 1));
                let array_dos = semana.slice(indices[2].i, (indices[3].i - 1));
                let array_tres = semana.slice(indices[3].i, (indices[4].i - 1));
                let array_cuatro = semana.slice(indices[4].i,);

                let id_row_semana_1 = "#semana1";
                render_semana_row(array_cero, id_row_semana_1);

                let id_row_semana_2 = "#semana2";
                render_semana_row(array_uno, id_row_semana_2);

                let id_row_semana_3 = "#semana3";
                render_semana_row(array_dos, id_row_semana_3);

                let id_row_semana_4 = "#semana4";
                render_semana_row(array_tres, id_row_semana_4);

                let id_row_semana_5 = "#semana5";
                render_semana_row(array_cuatro, id_row_semana_5);
            }
        }

        // render semana en row table
        function render_semana_row(array, id_div) {
            let array_inicio = array[0].param.split(" ", 2);
            let array_final = array.pop().param.split(" ", 2);
            let titulo_inicio = array_inicio[0] + " &nbsp; " + array_inicio[1];
            let titulo_final = array_final[0] + " &nbsp; " + array_final[1];
            let suma = 0;
            array.forEach(function (persona, index) {
                suma = suma + Number(persona.tardanza);
            });

            let icon_check = "";
            let fecha_actual = fechaActual();
            array.forEach(function (fechas, index) {
                if (fechas.fecha_dia.length > 0) {
                    if (Date.parse(fecha_actual) >= Date.parse(fechas.fecha_dia)) {
                        if (suma > 30) {
                            icon_check = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='fa fa-close' style='color: #ee3e27'></i>";
                        } else {
                            icon_check = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='fa fa-check' style='color: #559F00'></i>";
                        }
                    }
                }
            });
            $(id_div).html("<td>Del &nbsp; " + titulo_inicio + " &nbsp; al &nbsp; " + titulo_final + "</td><td>" + minutos_a_horas(suma) + " " + icon_check + "</td>");
        }

        function render_semana_row_option(array, id_div) {
            let array_inicio = array[0].param.split(" ", 2);
            let array_final = array.pop().param.split(" ", 2);
            let titulo_inicio = array_inicio[0] + " &nbsp; " + array_inicio[1];
            let titulo_final = array_final[0] + " &nbsp; " + array_final[1];
            let suma = 0;
            array.forEach(function (persona, index) {
                suma = suma + Number(persona.tardanza);
            });
            let icon_check = "";
            let fecha_actual = fechaActual();
            array.forEach(function (fechas, index) {
                if (fechas.fecha_dia.length > 0) {
                    if (Date.parse(fecha_actual) >= Date.parse(fechas.fecha_dia)) {
                        if (suma > 30) {
                            icon_check = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='fa fa-close' style='color: #ee3e27'></i>";
                        } else {
                            icon_check = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='fa fa-check' style='color: #559F00'></i>";
                        }
                    }
                }
            });
            $('#semana_no_lunes').html("<td>Del &nbsp; " + titulo_inicio + " &nbsp; al &nbsp; " + titulo_final + "</td><td>" + minutos_a_horas((suma + Number($('#suma_semana_pasada').text()))) + " " + icon_check + "</td>");
        }

        // de minutos a horas y minutos
        function minutos_a_horas(mins) {
            let h = Math.floor(mins / 60);
            let m = mins % 60;
            h = h < 10 ? '0' + h : h;
            m = m < 10 ? '0' + m : m;
            return `${h}:${m}:00`;
        }

        // obtener fecha actual
        function fechaActual() {
            let hoy = new Date();
            let dd = hoy.getDate();
            let mm = hoy.getMonth() + 1;
            let yyyy = hoy.getFullYear();

            dd = addZero(dd);
            mm = addZero(mm);
            return yyyy + '-' + mm + '-' + dd;
        }

        function addZero(i) {
            if (i < 10) {
                i = '0' + i;
            }
            return i;
        }

    });

});
$(function () {
    view_password();
    $("#view_password").click(view_password);
});

function view_password() {
    if ($('#view_password').is(':checked')) {
        $('.password').attr('type', 'text');
    } else {
        $('.password').attr('type', 'password');
    }
}

function openShowModal(t, funcOK) {
    $.ajax(t).done(function (t) {
        $("#show").html(t).foundation("open")
        funcOK();
    })
}

function SelectNested() {
    $("select.faq_service").on("change", function () {

        var id = $(this).val();
        var name = "input#" + $(this).attr("id").toString().replace("service_id", "faq_category_id")
        $(name).find('option').remove();
        $(name).val(0);
        $(name).attr("term", 0)
        $(name).attr("condicional", id)
        SelectTwoAjax();

    });
}

function ValidateEmail(input) {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
        return true;
    } else {
        return false;

    }

}

window.SelectTwo = function () {

    $('select.select2').select2({
        placeholder: "Buscar",
        placeholder: function () {
            $(this).data('placeholder');
        },
        allowClear: true
    })
};


var currgeocoder;


function getLocation() {

    function successLocation(position) {
        //console.log("entro:")
        var geo_loc = processGeolocationResult(position);
        var currLatLong = geo_loc.split(",");
        initializeCurrent(currLatLong[0], currLatLong[1]);
    }

    function errorLocation(error) {
        var html = "";
        if (error.code == 1)
            html = (`Error: <br>Codigo: ${error.code} - La geolocalización falló porque la página no tiene permiso para hacerlo. Por favor habilitalo`)
        else if (error.code == 2)
            html = (`Error: <br>Codigo: ${error.code} - La geolocalización falló. Por favor verifica que tienes activado tu GPS y con permisos para YANAPA`)
        else if (error.code == 3)
            html = (`Error: <br>Codigo: ${error.code} - El tiempo de esperá alcanzado (Timeout). Reintenta (RECARGANDO LA PÁGINA) y si el error persiste comunicate con SOPORTE`)
        else
            html = ("Ocurrió un error no DOCUMENTADO, consulta con SOPORTE")

        if (error.code == 1 || error.code == 2) {
            document.cookie = "lt=" + btoa(0);
            document.cookie = "ln=" + btoa(0);
        }
        $("#message_marcar").html(html)
        $(".validate_lat_row").html(`<div class="large-4 medium-4 large-centered medium-centered columns">
          <div class="callout warning text-center bold red">${html}
          </div>
        </div>`)

        $("#solicitar_auth").addClass("hide");
        $(".load_lat").addClass("hide");
    }

    //Set geo location  of lat and long
    $("#solicitar_auth").addClass("hide");
    if (navigator.geolocation) {

        $(".load_lat").removeClass("hide");
        navigator.geolocation.getCurrentPosition(successLocation, errorLocation, {
            timeout: 45000,
            enableHighAccuracy: true,
        });
        navigator.geolocation.watchPosition(successLocation);


    } else {
        $("#message_marcar").text("DEBE ACTIVAR LA LOCALIZACIÓN")
        $("#solicitar_auth").addClass("hide");
        $(".load_lat").addClass("hide");
    }


    //Get geo location result

    function processGeolocationResult(position) {
        var html5Lat = position.coords.latitude; //Get latitude
        var html5Lon = position.coords.longitude; //Get longitude
        var html5TimeStamp = position.timestamp; //Get timestamp
        var html5Accuracy = position.coords.accuracy; //Get accuracy in meters
        return (html5Lat).toFixed(8) + ", " + (html5Lon).toFixed(8);
    }

    //Check value is present or not & call google api function

    function initializeCurrent(latcurr, longcurr) {

        //currgeocoder = new google.maps.Geocoder();
        //console.log(latcurr + "-- ######## --" + longcurr);
        $(".load_lat").addClass("hide");
        if (latcurr != '' && longcurr != '') {
            document.cookie = "lt=" + btoa(latcurr);
            document.cookie = "ln=" + btoa(longcurr);
            var metro = distance(parseFloat($(".geo-lat").val()), parseFloat($(".geo-lon").val()), latcurr, longcurr);
            //console.log(metro)
            $(".validar_lat").removeClass("hide");


        }
    }


}

function get_cookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    } else {
        return null
    }
}

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
            dist = dist * 1.609344
        }
        if (unit == "N") {
            dist = dist * 0.8684
        }
        return dist;
    }
}
 window.SelectTwoAjax = function() {

    $('.select2-ajax').each(function () {
        var controller = $(this).attr("controller");
        var key = $(this).attr("key");
        var tipo = $(this).attr("tipo");
        // condicional es para alguna condición que se necesite
        var condicional = $(this).attr("condicional");

        $(this).select2({
            placeholder: "Buscar " + tipo,
            initSelection: function (element, callback) {
                return $.ajax({
                    url: "/" + controller + "/find_ajax.json",
                    dataType: 'json',
                    data: {
                        term: $(element).val(),
                        key: key,
                        condicional: condicional
                    },
                    success: function (data) {
                    }
                }).done(function (data) {
                    return callback(data);
                });
            },
            formatNoMatches: function () {
                return 'No existe';
            },
            formatSearching: function () {
                return 'Buscando...';
            },
            formatInputTooShort: function () {
                return "Ingresa 1 letra";
            },
            id: function (object) {
                if (key == "key") {
                    return object.key;
                } else if (key == "id") {
                    return object.id;
                } else if (key == "codigo") {
                    return object.codigo;
                } else if (key == "token") {
                    return object.token;
                }
            },
            minimumInputLength: 1,
            ajax: {
                url: "/" + controller + "/all_ajax.json",
                dataType: 'json',
                quietMillis: 300,
                data: function (search) {
                    return {
                        term: search,
                        key: key,
                        condicional: condicional
                    };
                },
                results: function (data) {
                    return { results: data.all_ajax };
                }
            },
            formatResult: searchFormatResult,
            formatSelection: searchFormatSelection,
            dropdownCssClass: "bigdrop"
        }).on("select2-opening", function () {

        });

        function searchFormatResult(all) {
            return (all.full);
        }

        function searchFormatSelection(all) {
            return (all.full);
        }
    });
}

window.EventoNumeroReunionCircle = function () {
    $(".get_google_meet_circle").unbind().on("change", function () {
        var selects = document.querySelectorAll("select.get_google_meet_circle")
        var code = []
        selects.forEach(function (e) {
            code.push($(e).val())
        })

        var codigo = $("#meeting_codigo").val();
        $.ajax({
            url: "/meetings/get_google_meet_circle",
            type: "POST",
            data: {
                circles_code: code.join(","),
                codigo: codigo
            },
            success: function (e) {
                if (e.success) {
                    //$("#meeting_google_meet").val(e.google_meet);
                    if (e.codigo)
                        $("#proximo-numero-meeting").text("Número de reunión: " + e.numero_reunion);
                    else
                        $("#proximo-numero-meeting").text("");
                }
            },
            error: function (e) {

            }
        })
    });
}
window.markjs = function () {

    // Read the keyword
    var keyword = [];
    $(".mark_search").each(function () {
        if ($(this).hasClass("select2-ajax")) {
            var value = $(this).select2('data');
            if (value) {
                for (var i = 0; i <= value.full.split(" ").length - 1; i++) {
                    if (value.full.split(" ")[i])
                        keyword.push(value.full.split(" ")[i])
                }
            }

        } else if ($(this).hasClass("select2")) {
            var value = $(this).select2('data');
            if (value) {
                for (var i = 0; i <= value.text.split(" ").length - 1; i++) {
                    if (value.text.split(" ")[i])
                        keyword.push(value.text.split(" ")[i])
                }
            }

        } else {
            var value = $(this).val();
            if (value) {
                keyword.push(value)
            }
        }


    })

    if (keyword == null || keyword == "") {

        keyword = new URLSearchParams(window.location.search).get('search')
    }
    // Determine selected options
    var options = {};

    var context = document.querySelector(".markjs");
    if (context) {
        var instance = new Mark(context);
        instance.unmark()
        if (keyword) {
            instance.mark(keyword, {
                accuracy: "partially"
            });
        }

    }


};






















